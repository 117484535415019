<template>
  <div>
    <div class="row pr-4 mt-4" v-for="(item, i) in transformObjectToArray(byStatus)" :key="i">
      <div class="col-2 text-end pl-0 pr-4 pb-0 pt-0 col-graph-label">{{ item.label }}</div>
      <div class="col-10 pb-0 pt-0">
        <div class="row">
          <div class="col-11 pl-0 pr-0 pb-2"><div :style="dynamicStyle(item.percentual)"></div></div>
          <div class="col-1 pr-0 pb-2 text-end col-qtd">{{ item.quantidade }}</div>
          <v-divider class="col-12"></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GraphAutoAnalysis',
  props: {
    byStatus: {
      type: Object,
      required: false,
    },
  },
  methods: {
    dynamicStyle(w) {
      return {
        width: `${w}%`,
        height: '16px',
        background: '#c73c44',
        borderRadius: '100px',
      };
    },
    transformObjectToArray(obj) {
      const resultArray = [];
      if (obj) {
        const total = Object.values(obj).reduce((sum, value) => sum + value, 0);

        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const percentage = Math.round((obj[key] / total) * 100);
            resultArray.push({
              label: key.charAt(0).toUpperCase() + key.slice(1),
              quantidade: obj[key],
              percentual: percentage,
            });
          }
        }
      }
      return resultArray;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  transition: width 0.3s ease;
}
.col-graph {
  width: 100%;
  height: 100%;
  background: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
}
.col-graph-label {
  color: #52526b;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}
.col-graph-container {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}
.col-qtd {
  color: #962d33;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
</style>
