var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.transformObjectToArray(_vm.byStatus), function (item, i) {
      return _c("div", { key: i, staticClass: "row pr-4 mt-4" }, [
        _c(
          "div",
          { staticClass: "col-2 text-end pl-0 pr-4 pb-0 pt-0 col-graph-label" },
          [_vm._v(_vm._s(item.label))]
        ),
        _c("div", { staticClass: "col-10 pb-0 pt-0" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "col-11 pl-0 pr-0 pb-2" }, [
                _c("div", { style: _vm.dynamicStyle(item.percentual) }),
              ]),
              _c("div", { staticClass: "col-1 pr-0 pb-2 text-end col-qtd" }, [
                _vm._v(_vm._s(item.quantidade)),
              ]),
              _c("v-divider", { staticClass: "col-12" }),
            ],
            1
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }